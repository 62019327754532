<template>
  <div
    class="record-play-box"
  >
    <div
      class="player-out-box"
    >
      <div
        id="white-player"
        class="white-player"
      />
    </div>
    <video
      id="replay-container"
      class="video-box"
    />
    <div
      class="progress-time"
      @click="handlePlay"
    >
      {{ progressTime }}
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { WhiteWebSdk } from 'white-web-sdk';
import CombinePlayerFactory from '@netless/combine-player';
import { getRoomToken, stopRecord } from '@/services/room';

export default {
  name: 'WhiteBoard',
  components: {
  },
  filters: {
  },
  props: {
    roomInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      appIdentifier: this.$config.APPIDENTIFIER,
      roomToken: '',
      player: null,
      combinePlayer: null,
      whiteWebSdk: null,
      progress: 0, // 进度比例
      duration: 0, // 总时长
      volumeProgress: 50,
      loadPlayerCount: 0,
      replayRoomCount: 0,

    };
  },
  computed: {
    progressTime() {
      if (this.player) {
        return this.player.progressTime;
      }
      return 0;
    },
    roomUUID() {
      return this.roomInfo.liveId;
    },
  },
  watch: {
  },
  async mounted() {
    this.whiteWebSdk = await new WhiteWebSdk({
      appIdentifier: this.appIdentifier,
    });
    this.getRoomToken();
    if (!this.roomUUID) {
      this.$message.error('缺少liveId');
      setTimeout(() => {
        window.location.href = 'about:blank';
      }, 5000);
    } else {
      this.loadPlayer();
    }
  },
  methods: {
    ...mapActions('login', ['showLoginDialog']),
    // 获取白板、音频、云信令token
    async getRoomToken() {
      this.roomToken = await getRoomToken({
        liveId: this.roomUUID,
      });
    },
    async loadPlayer() {
      if (!this.roomInfo?.watchVideoUrl) {
        this.handleStopRecord();
        this.$message.error('无播放资源');
        return;
      }
      this.loadPlayerCount += 1;
      this.whiteWebSdk.isPlayable({
        room: this.roomUUID,
        roomToken: this.roomToken,
      }).then((isPlayable) => {
        // isPlayable === true 表示，这个时间段的录像已生成，可以播放
        if (isPlayable) {
          this.startPlayer();
        }
      }).catch((err) => {
        // 获取失败
        this.$message.error(`${err} 云端录制内容还未生成, 1s后重新获取`);
        if (this.loadPlayerCount === 1) {
          setTimeout(() => {
            this.loadPlayer();
          }, 1000);
        } else {
          this.handleStopRecord();
        }
      });
    },
    async startPlayer() {
      this.replayRoomCount += 1;
      const params = {
        room: this.roomUUID,
        roomToken: this.roomToken,
      };
      this.whiteWebSdk.replayRoom(params, {
        onPhaseChanged: (phase) => {
          // eslint-disable-next-line no-console
          console.log(phase);
        },
        onStoppedWithError: (err) => {
          this.$message.error(err);
        },
        onPlayerStateChanged: (modifyState) => {
          const { sceneState } = modifyState;
          // eslint-disable-next-line no-console
          console.log(sceneState, 'onPlayerStateChanged');
          if (sceneState) {
            this.roomStyle();
          }
        },

      }).then((player) => {
        // 获取到回放数据，成功初始化播放器实例
        this.player = player;
        this.handleCombinePlayer();
      }).catch((err) => {
        // 获取回放数据失败
        this.$message.error(`${err} 获取回放数据失败,1s重新获取`);
        if (this.replayRoomCount === 1) {
          setTimeout(() => {
            this.startPlayer();
          }, 1000);
        } else {
          this.handleStopRecord();
        }
      });

      // await this.handleStartRecord();
    },
    handleCombinePlayer() {
      this.player.bindHtmlElement(document.getElementById('white-player'));
      this.player.disableCameraTransform = true; // 是否禁止用户通过触屏手势或鼠标滚轮移动或缩放调整视角
      this.player.disableDeviceInputs = true; // 禁止操作白板
      const videoDom = document.getElementById('replay-container');
      const factoryParams = {
        // url: 'https://pic.jingdata.com/avatar/202103/03072829/w7c4kvuu5jtz25hc.mp3', // 暂用网络文件代替
        url: this.roomInfo.watchVideoUrl || 0,
        videoDOM: videoDom, // 用于存放视频播放器的 div 节点
      };

      this.combinePlayer = new CombinePlayerFactory(this.player, factoryParams).create();
      this.combinePlayer.setOnStatusChange((status, message) => {
        // eslint-disable-next-line no-console
        console.log('[combinePlayer] 状态发生改变: ', status, message);
        if (status === 'Ended') {
          this.handleStopRecord();
        }
        if (status === 'Disabled') {
          this.$message.error('netless/combine-player 出现意外或出现了bug');
        }
      });
      // 不能保证拿到video时长
      setTimeout(() => {
        this.duration = this.combinePlayer.timeDuration.duration || 0;
        videoDom.volume = this.volumeProgress / 100;
      }, 1500);

      setTimeout(() => {
        if (!this.duration) {
          this.duration = this.combinePlayer.timeDuration.duration || 0;
          videoDom.volume = this.volumeProgress / 100;
          this.handlePlay();
        } else {
          this.handlePlay();
        }
      }, 2500);
    },
    async handlePlay() {
      this.combinePlayer.play();
      this.$emit('handleHideLoading');
    },

    async handleStopRecord() {
      await stopRecord({
        liveId: this.roomInfo.liveId,
      });
    },
    roomStyle() {
      if (this.player) {
        this.player.moveCamera({
          scale: 1,
        });
        this.player.scalePptToFit('immediately');
        this.player.refreshViewSize();
      }
    },
    // async handleStartRecord() {
    //   await startRecord({
    //     liveId: this.roomInfo.liveId,
    //     recordingUrl: `https://live-test.capitalcoffee.club/#/recordLive/${this.roomInfo.id}`,
    //   });
    // },

  },
};
</script>

<style lang="scss" scoped>
@import './index';
</style>
